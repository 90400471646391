import { ReactNode } from 'react';
import { redirect } from 'next/navigation';
import {
  MutableNonNullableAsset,
  MutableNonNullableUser,
  TradeInnerTranslatedValues,
} from '../types';
import {
  AssetType,
  TradeControllerVariants,
  TradeScreenTranslations,
} from '../../utils';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { applyAppropriatenessCheck } from '../utils/applyAppropriatenessCheck';
import { getTranslationsPerTradeType } from './utils/getTranslationsPerTradeType';
import { getServerUserInfo } from '../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/getServerUserInfo';
import { getAssetById } from '../../../common/gqlActions';
import {
  NavigationBackButton,
  NavigationButton,
} from '../../../navigation/index';
import { NavigationBar } from '../../../common';

export interface TradeControllerPropsBase {
  assetId: string;
  currentLocale: string;
  variant: TradeControllerVariants;
  currency: string;
  translations: TradeScreenTranslations;
  // is used to disable the appropriateness check when this component is used purely for background blurry purposes
  skipCheckAppropriateness?: boolean;
  children?: (params: {
    staticData: {
      currentLocale: string;
      translations: TradeScreenTranslations;
      variant: TradeControllerVariants;
      currency: string;
      translatedInnerValues: TradeInnerTranslatedValues;
    };
    initialData: {
      assetData: MutableNonNullableAsset;
      userData: MutableNonNullableUser;
    };
  }) => ReactNode;
}

const TradeController = async ({
  variant,
  children,
  assetId,
  currentLocale,
  currency,
  translations,
  skipCheckAppropriateness,
}: TradeControllerPropsBase) => {
  const assetByIdResponse = await getAssetById(assetId);

  const { assetById } = assetByIdResponse.data ?? {};

  const userData = await getServerUserInfo();

  if (!assetById) {
    if (!isDevelopmentEnv()) {
      redirect('/error/generic');
    }

    return null;
  }

  if (variant === 'buy' && !skipCheckAppropriateness) {
    await applyAppropriatenessCheck({
      asset: {
        assetId: assetId,
        assetType: assetById.__typename as AssetType,
      },
    });
  }

  const { translatedInnerValues } = getTranslationsPerTradeType({
    assetType: assetById?.__typename,
    translations,
    variant,
  });

  const variantsTitles: Record<TradeControllerVariants, string> = {
    buy: translations.buy,
    sell: translations.sell,
    savings: translations.savingsPlan,
  };

  return (
    <>
      <NavigationBar
        title={variantsTitles[variant]}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton icon="dismiss" to="/assets/portfolio" />
        }
      />

      {children &&
        children({
          staticData: {
            currentLocale,
            translations,
            variant,
            currency,
            translatedInnerValues: translatedInnerValues,
          },
          initialData: {
            assetData: assetById,
            userData: userData as MutableNonNullableUser,
          },
        })}
    </>
  );
};

export { TradeController };
