import {
  Status as DesignStatus,
  StatusProps,
} from '@bts-web/design-system/component/status';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

type ThemedStatusProps = {
  visual?: 'filledNeutralLight' | 'filledNeutralDark';
  size?: 'medium';
} & StatusProps;

const Status: FC<ThemedStatusProps> = ({
  visual = 'filledNeutralLight',
  text,
  size = 'medium',
}) => {
  return (
    <DesignStatus
      text={text}
      className={css({
        borderRadius: '32px',
        display: 'inline-block',
        ...(visual === 'filledNeutralDark'
          ? {
              bg: 'neutrals.fill_primary',
              color: 'neutrals.text_primary_inverted',
            }
          : {}),
        ...(visual === 'filledNeutralLight'
          ? {
              bg: 'neutrals.fill_quinary',
              color: 'neutrals.text_primary',
            }
          : {}),

        ...(size === 'medium'
          ? {
              paddingTop: 'extra_small_3',
              paddingRight: 'extra_small_2',
              paddingBottom: 'extra_small_3',
              paddingLeft: 'extra_small_2',
              fontSize: 'caption.medium_medium',
              fontWeight: 'caption.medium_medium',
              lineHeight: 'caption.medium_medium',
              letterSpacing: 'caption.medium_medium',
            }
          : {}),
      })}
    />
  );
};

export { Status };
