'use client';

import { TFunction } from 'i18next';
import { useRouter } from 'next/navigation';
import {
  getRoundedPrecision,
  intlAssetValueFormatting,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TransactionSuccessModal } from '../../../../common';
import { ModalComponent } from '@bts-web/utils-context';
import { css, stack } from '@bts-web/utils-style-engine';
import { getSavingsPlansTranslations } from '../../../../savings-plans/utils/getSavingsPlansTranslations';

export type SavingsPlanSuccessModalProps = {
  locale: string;
  savingsPlansData: {
    name: string;
    assetName: string;
    assetLogoUrl: string;
    fiatAmount: PrecisionFloat;
    nextRecurrence: string;
  };
};

export const SavingsPlanSuccessModal: ModalComponent<
  SavingsPlanSuccessModalProps
> = ({ locale, savingsPlansData, onClose }) => {
  const router = useRouter();

  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  const fiatAmount = intlAssetValueFormatting(
    getRoundedPrecision(savingsPlansData.fiatAmount),
    {
      locale,
      currency: 'EUR',
      fractionDigits: savingsPlansData?.fiatAmount?.precision,
    },
  );

  const description = (
    <div
      className={stack({
        gap: 'extra_small_2',
        fontSize: 'body.medium',
        fontWeight: 'body.medium',
        letterSpacing: 'body.medium',
        lineHeight: 'body.medium',
        color: 'neutrals.text_secondary',
      })}
    >
      <div>
        <span>{translations.savingsPlanSuccessBodyLine1}</span>
        <span
          className={css({
            color: 'neutrals.text_primary',
          })}
        >
          &nbsp;
          {t('savings_plan_success_body_line_2', {
            amount: fiatAmount,
            assetName: savingsPlansData.assetName,
          })}
        </span>
        <span>{translations.savingsPlanSuccessBodyLine3}</span>
      </div>
      <div>
        <span>{translations.savingsPlanSuccessBody2Line1}</span>
        &nbsp;
        <span
          className={css({
            color: 'neutrals.text_primary',
          })}
        >
          {translations.savingsPlanSuccessBody2Line2}
        </span>
        &nbsp;
        <span>{translations.savingsPlanSuccessBody2Line3}</span>
      </div>
    </div>
  );

  return (
    <TransactionSuccessModal
      title={translations.savingsPlanSuccessTitle}
      subtitle={t('savings_plan_success_subtitle', {
        name: savingsPlansData.name,
      })}
      description={description}
      continueButtonText={translations.savingsPlanSuccessCheckItOut}
      cancelButtonText={translations.okGoToPortfolio}
      logo={{
        url: savingsPlansData.assetLogoUrl,
        name: savingsPlansData.assetName,
      }}
      onCancelClick={() => router.push('/assets/portfolio')}
      onContinueClick={() => {
        console.log('Confirm Click');
      }}
      poweredByLabel={translations.poweredByBitpanda}
      onClose={onClose}
    />
  );
};
