import { Dialog, DialogProps } from '@bts-web/design-system/component/dialog';
import { Icon } from '@bts-web/design-system/component/icon';
import { css, Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import { FC, ReactElement } from 'react';

type DialogBaseProps = {
  visual: 'primary' | 'secondary';
  size: 'small' | 'fullscreen' | 'medium';
  belowHeaderElement?: ReactElement;
  backAction?: () => void;
  disableBackdropClose?: boolean;
  onMobilefullscreen?: boolean;
} & DialogProps['external'];

const overlayStyles = css({
  position: 'fixed',
  inset: '0',
  animation: 'overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
  backgroundColor: 'rgba(166, 166, 166, 0.50)',
  backdropFilter: 'blur(6px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
});

const DialogBase: FC<DialogBaseProps> = ({
  size,
  visual = 'primary',
  children,
  id,
  onOpenChange,
  open,
  animate,
  title,
  closeIcon = <Icon icon="close" theme="regular" size="16" />,
  belowHeaderElement,
  backAction,
  disableBackdropClose,
  onMobilefullscreen,
}) => {
  return (
    <Dialog
      disableBackdropClose={disableBackdropClose}
      id={id}
      backAction={backAction}
      belowHeaderElement={belowHeaderElement}
      open={open}
      onOpenChange={onOpenChange}
      animate={animate}
      title={title}
      overlayStyle={{
        zIndex: Z_INDEX_LIBRARY.MODAL,
      }}
      overlayClassName={overlayStyles}
      contentClassName={css({
        display: 'flex',
        flexDirection: 'column',
        ...(visual === 'primary'
          ? {
              backgroundColor: 'brand.fill_secondary',
            }
          : {}),
        ...(visual === 'secondary'
          ? {
              backgroundColor: 'screen_background.primary',
            }
          : {}),
        ...(size === 'fullscreen'
          ? {
              width: '100vw',
              height: '100vh',
            }
          : {}),
        ...(size === 'small'
          ? {
              width: '90vw',
              maxWidth: '614px',
            }
          : {}),
        ...(size === 'medium'
          ? {
              width: '90vw',
              maxWidth: '1000px',
            }
          : {}),
        borderRadius: '8px',
        maxHeight: '98%',
        overflow: 'hidden',
        animation: 'slideUpAndFade 150ms cubic-bezier(0.87, 0, 0.13, 1)',
        '& [data-element="dialog-header"]': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& [data-element="dialog-header-space"]': {
            width: '68px',
            height: '68px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:last-child': {
              marginLeft: 'auto',
            },
          },
          '& [data-element="dialog-title"]': {
            flex: '1',
            py: { base: 'extra_small', lg: 'small' },
            px: { base: 'small', lg: 'medium' },
            color: 'neutrals.text_primary',
            fontSize: {
              lg: 'headline.medium_medium',
              base: 'headline.small_medium',
            },
            fontWeight: 'headline.medium_medium',
            letterSpacing: 'headline.medium_medium',
            lineHeight: {
              lg: 'headline.medium_medium',
              base: 'headline.small_medium',
            },
            textAlign: 'center',
          },
          '& [data-element="dialog-close-btn"], & [data-element="dialog-back-btn"]':
            {
              cursor: 'pointer',
              color: 'neutrals.text_primary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            },
        },
        '& [data-element="dialog-inner-content"]': {
          paddingX: { base: 'small', lg: 'medium' },
          paddingBottom: { base: 'small', lg: 'medium' },
          flex: '1',
          overflowY: 'auto',
        },
        ...(onMobilefullscreen
          ? {
              p: 'extra_small_2',
              textAlign: 'center',
              flex: 'auto',
              width: { base: '100%' },
              height: { base: '100%', lg: 'auto' },
              maxWidth: { base: '100%', lg: '620px' },
              maxHeight: { base: '100%' },
              overflow: 'auto',
              backgroundColor: { base: 'neutrals.card_fill_primary' },
              borderRadius: {
                base: 0,
                lg: '4px',
              },
            }
          : {}),
        boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.15)`,
      })}
      backIcon={<Icon icon="arrow-left" theme="regular" size="16" />}
      closeIcon={closeIcon}
    >
      {children}
    </Dialog>
  );
};

export { DialogBase };
