import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { capitalizeFirstLetter } from '@bts-web/utils-formatting';

export interface BelowInputInfoTableProps {
  items: { title?: string; value?: string }[];
}

export const BelowInputInfoTable: FC<BelowInputInfoTableProps> = ({
  items,
}) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <table
      className={css({
        width: '100%',
      })}
      data-testid="below-input-info-table"
    >
      <tbody
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
        })}
      >
        {items.map((item) => {
          const { title, value } = item;

          return (
            <tr
              key={`${title}-${value}`}
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
              })}
              data-testid="below-input-info-table-row"
            >
              <td
                className={css({
                  color: 'neutrals.text_secondary',
                  fontSize: '12px',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                })}
              >
                {capitalizeFirstLetter(title)}
              </td>

              <td
                className={css({
                  color: 'neutrals.text_primary',
                  fontSize: 'body.medium',
                  fontWeight: 'body.medium',
                  letterSpacing: 'body.medium',
                  lineHeight: 'body.medium',
                  textAlign: 'right',
                })}
              >
                {value}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
