'use client';

import { useCallback, useEffect, useState } from 'react';
import { i18n } from '@bts-web/utils-lokalise';
import { useRouter } from 'next/navigation';
import type { UserLanguage } from '@bts-web/data-layer/server';
import { setCookieValue } from '@bts-web/utils-storage';
import {
  useClientUserInfo,
  useClientUserInfoUpdater,
} from '../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { useAppNotification } from '../../notifications/NotificationContextProvider';
import { postUpdateBusinessUser } from '../../common/gqlActions/postUpdateBusinessUser.action';
import { postUpdateUser } from '../../common/gqlActions/postUpdateUser.action';

export const useChangeLanguage = ({
  translations,
}: {
  translations: {
    errorTitle: string;
    errorSubtitle: string;
  };
}) => {
  const router = useRouter();

  const { refreshUserInfo } = useClientUserInfoUpdater();

  const { userType } = useClientUserInfo();

  const { setAppNotification } = useAppNotification();

  const [isUserUpdateLoading, setIsUserUpdateLoading] = useState(false);

  const defaultSelectedLanguage = i18n.resolvedLanguage as UserLanguage;

  const updateLanguageErrorHandler = useCallback(
    (error: Error): void => {
      i18n.changeLanguage(defaultSelectedLanguage, () => {
        setCookieValue('app_language', defaultSelectedLanguage as string);

        router.refresh();
      });

      setAppNotification({
        title: translations.errorTitle,
        subtitle: translations.errorSubtitle,
        withClose: true,
        visual: 'error',
      });

      console.log('[ERROR][change language]', error);

      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppNotification],
  );

  useEffect(() => {
    setIsUserUpdateLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage]);

  const onChangeLanguage: (params: {
    languageValue: string;
    onError?: () => void;
  }) => void = useCallback(
    async ({ languageValue, onError }) => {
      setIsUserUpdateLoading(true);

      const updateUserAction =
        userType === 'User' ? postUpdateUser : postUpdateBusinessUser;

      const variablesInput = {
        language: languageValue.toUpperCase() as UserLanguage,
      };

      try {
        await updateUserAction(variablesInput);

        i18n.changeLanguage(languageValue, () => {
          setCookieValue('app_language', languageValue as string);
        });

        await refreshUserInfo();
      } catch (error) {
        if (onError) {
          onError();
        }

        setIsUserUpdateLoading(false);

        updateLanguageErrorHandler(error as Error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateLanguageErrorHandler, userType],
  );

  return {
    isUserUpdateLoading,
    onChangeLanguage,
  };
};
