import {
  PrecisionFloat,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode } from 'react';
import { Mutable } from '../../../trade/utils';
import { AssetByIdViewQuery } from '@bts-web/data-layer/server';
import { AssetImage } from '../../../common';

const assetFiatPriceStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.medium_medium',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'extra_small_2',
  textAlign: 'right',
  '& > *': {
    whiteSpace: 'nowrap',
  },
});

const assetNameStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: '500',
  fontSize: 'title.medium_medium',
  overflow: 'hidden',
  '& img': {
    flexShrink: 0,
  },
  '& > *': {
    lineClamp: 2,
  },
});

const assetPriceInfoStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  pt: 'medium',
  pb: 'extra_small',
  gap: 'extra_small',
});

type MutableNonNullableAsset = NonNullable<
  Mutable<NonNullable<AssetByIdViewQuery['response']>['assetById']>
>;

export type TradeAssetInfoDisplayProps = {
  currentLocale: string;
  assetData: Pick<MutableNonNullableAsset, 'name' | 'logoUrl'>;
  price: PrecisionFloat;
  symbol: string | null;
  loaderElement?: ReactNode;
  settings?: {
    hideTopBorder?: boolean;
  };
};

export const TradeAssetInfoDisplay: FC<TradeAssetInfoDisplayProps> = ({
  currentLocale,
  assetData,
  price,
  symbol,
  loaderElement,
  settings,
}) => {
  const { hideTopBorder } = settings ?? {};

  const { name, logoUrl } = assetData;

  const oneUnitFiatPrice = price
    ? `1 ${symbol} = ${intlAssetValueFormatting(price.value, {
        locale: currentLocale,
        notation: 'standard',
        currency: 'EUR',
        fractionDigits: price?.precision,
      })}`
    : '';

  return (
    <>
      {!hideTopBorder && (
        <div
          className={css({
            borderTop: '1px solid',
            borderColor: 'neutrals.divider',
          })}
        />
      )}

      <div
        data-testid="assetPriceInfo"
        data-element="details-container"
        className={assetPriceInfoStyles}
      >
        <div className={assetNameStyles}>
          <AssetImage
            width={24}
            height={24}
            src={logoUrl as string}
            alt={symbol as string}
            aria-label="asset image"
            placeholder="empty"
          />
          <span>{name}</span>
        </div>

        <div data-element="info" className={assetFiatPriceStyles}>
          <span aria-label="asset fiat price">{oneUnitFiatPrice}</span>

          {loaderElement && <span>{loaderElement}</span>}
        </div>
      </div>
    </>
  );
};
