'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { ReactNode, useCallback, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { isThisMonth, isPast } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';

export interface DatePickerProps {
  internal: {
    className: string;
    overlayClassName: string;
    popperContentClassName: string;
  };
  external: {
    zIndex?: number;
    cancelLabel: string;
    confirmLabel: string;
    value: string | undefined;
    sideOffset?: number;
    disablePast?: boolean;
    onChange: (date: string) => void;
    children: ReactNode;
  };
}

export const getTomorrowsISODate = (): string => {
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow.toISOString();
};

const DatePicker = ({
  className,
  cancelLabel,
  confirmLabel,
  overlayClassName,
  popperContentClassName,
  sideOffset,
  children,
  onChange,
  value = new Date().toISOString(),
  zIndex = Z_INDEX_LIBRARY.MODAL,
  disablePast,
}: DatePickerProps['internal'] & DatePickerProps['external']) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    if (selectedDate) {
      onChange(selectedDate.toISOString());
    }

    setSelectedDate(undefined);

    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedDate(undefined);

    setIsOpen(false);
  };

  const isPastDate = useCallback(
    (date: Date | number) => disablePast && isPast(date),
    [disablePast],
  );

  const dateValue = new Date(value);

  return (
    <Popover.Root open={isOpen} onOpenChange={() => setIsOpen(true)}>
      <Popover.Trigger data-element="date-picker-popover-trigger">
        {children}
      </Popover.Trigger>
      {isOpen && (
        <div
          className={overlayClassName}
          data-testid="date-picker-overlay"
          style={{ zIndex }}
        />
      )}
      <Popover.Content
        sideOffset={sideOffset}
        align="start"
        data-testid="date-picker-content"
        className={popperContentClassName}
        style={{
          zIndex: zIndex,
        }}
      >
        <DayPicker
          mode="single"
          className={className}
          selected={selectedDate || dateValue}
          disabled={{
            before: new Date(getTomorrowsISODate()),
          }}
          onSelect={setSelectedDate}
          footer
          required
          defaultMonth={dateValue}
          components={{
            CaptionLabel: ({ children }) => {
              return (
                <div
                  data-element="date-picker-caption"
                  data-testid="date-picker-caption"
                >
                  {children}
                </div>
              );
            },
            Nav: ({ onNextClick, previousMonth, onPreviousClick }) => {
              const now = new Date();

              const earliestDate = previousMonth
                ? new Date(Math.min(now.getTime(), previousMonth?.getTime()))
                : now;

              const canPrev = earliestDate
                ? !isPastDate(earliestDate) || isThisMonth(earliestDate)
                : false;

              return (
                <div
                  data-element="date-picker-nav"
                  data-testid="date-picker-nav"
                >
                  {onPreviousClick && (
                    <button
                      onClick={onPreviousClick}
                      disabled={!canPrev}
                      tabIndex={1}
                    >
                      <Icon icon="chevron-left" size="24" theme="regular" />
                    </button>
                  )}

                  {onNextClick && (
                    <button onClick={onNextClick}>
                      <Icon icon="chevron-right" size="24" theme="regular" />
                    </button>
                  )}
                </div>
              );
            },
            Footer: () => {
              return (
                <div data-element="date-picker-footer">
                  <button
                    onClick={handleCancel}
                    data-testid="date-picker-cancel"
                  >
                    {cancelLabel}
                  </button>
                  <button
                    onClick={handleConfirm}
                    disabled={!selectedDate}
                    data-testid="date-picker-confirm"
                  >
                    {confirmLabel}
                  </button>
                </div>
              );
            },
          }}
        />
      </Popover.Content>
    </Popover.Root>
  );
};

export { DatePicker };
