import { CreateCryptoWithdrawalInput } from './__generated__/CreateCryptoWithdrawalOfferMutation.graphql';
/* --- QUERIES ---*/

export type {
  AmlQuestionsViewQuery,
  AmlQuestionsViewQuery$data,
} from './__generated__/AmlQuestionsViewQuery.graphql';

export { default as AmlQuestionsViewQueryNode } from './__generated__/AmlQuestionsViewQuery.graphql';

export type {
  AssetsViewQuery,
  AssetsViewQuery$variables,
  AssetsViewQuery$data,
  AssetsListSortBy,
  AssetsListGroup,
  SortOrder,
} from './__generated__/AssetsViewQuery.graphql';

export { default as AssetsViewQueryNode } from './__generated__/AssetsViewQuery.graphql';

export {
  type AllAssetsForTransferQuery,
  type AllAssetsForTransferQuery$data,
  type AllAssetsForTransferQuery$variables,
  default as AllAssetsForTransferQueryNode,
} from './__generated__/AllAssetsForTransferQuery.graphql';

export {
  type AssetByIdViewQuery,
  type AssetByIdViewQuery$data,
  type AssetByIdViewQuery$variables,
  default as AssetByIdViewQueryNode,
} from './__generated__/AssetByIdViewQuery.graphql';

export type {
  AssetByTypeViewQuery$variables,
  AssetHistoryPeriod,
  AssetByTypeViewQuery,
  AssetByTypeViewQuery$data,
} from './__generated__/AssetByTypeViewQuery.graphql';

export type {
  UserFullInfoQuery,
  UserFullInfoQuery$data,
} from './__generated__/UserFullInfoQuery.graphql';

export { default as UserFullInfoQueryQueryNode } from './__generated__/UserFullInfoQuery.graphql';

export { default as AssetDetailsViewQueryNode } from './__generated__/AssetDetailsViewQuery.graphql';

export type {
  AssetDetailsViewQuery,
  AssetDetailsViewQuery$data,
} from './__generated__/AssetDetailsViewQuery.graphql';

export { default as AssetByTypeViewQueryNode } from './__generated__/AssetByTypeViewQuery.graphql';

export type {
  TradeOfferViewQuery,
  TradeOfferViewQuery$data,
} from './__generated__/TradeOfferViewQuery.graphql';

export { default as TradeOfferViewQueryNode } from './__generated__/TradeOfferViewQuery.graphql';

export type {
  UserDetailsQuery,
  UserDetailsQuery$data,
} from './__generated__/UserDetailsQuery.graphql';

export { default as UserDetailsQueryNode } from './__generated__/UserDetailsQuery.graphql';

export type { TermsAndConditionsViewQuery } from './__generated__/TermsAndConditionsViewQuery.graphql';

export { default as TermsAndConditionsViewQueryNode } from './__generated__/TermsAndConditionsViewQuery.graphql';

export type {
  AppropriatenessQuestionsViewQuery,
  AppropriatenessQuestionsViewQuery$data,
  AppropriatenessQuestionsViewQuery$variables,
} from './__generated__/AppropriatenessQuestionsViewQuery.graphql';

export { default as AppropriatenessQuestionsViewQueryNode } from './__generated__/AppropriatenessQuestionsViewQuery.graphql';

export {
  type TransactionFinalizedSummaryDataQuery,
  type TransactionFinalizedSummaryDataQuery$data,
  type TransactionFinalizedSummaryDataQuery$variables,
  default as TransactionFinalizedSummaryDataQueryNode,
} from './__generated__/TransactionFinalizedSummaryDataQuery.graphql';

export {
  type PortfolioPeriod,
  type PortfolioViewQuery,
  type PortfolioViewQuery$data,
  type PortfolioViewQuery$variables,
  default as PortfolioViewQueryNode,
} from './__generated__/PortfolioViewQuery.graphql';

export {
  default as CorporateOnboardingRiskQuestionsQueryNode,
  type CorporateOnboardingRiskQuestionsQuery,
  type CorporateOnboardingRiskQuestionsQuery$data,
  type CorporateOnboardingRiskQuestionsQuery$variables,
} from './__generated__/CorporateOnboardingRiskQuestionsQuery.graphql';

export {
  type OnboardCorporateUserMutation,
  type OnboardCorporateUserMutation$data,
  type OnboardCorporateUserMutation$variables,
  type OnboardBusinessUserInput,
  type BusinessUserRisks,
  default as OnboardCorporateUserMutationQueryNode,
} from './__generated__/OnboardCorporateUserMutation.graphql';

export type {
  MyAssetsViewQuery,
  MyAssetsViewQuery$data,
} from './__generated__/MyAssetsViewQuery.graphql';

export { default as MyAssetsViewQueryNode } from './__generated__/MyAssetsViewQuery.graphql';

export {
  type PortfolioAssetGroupsQuery,
  type PortfolioAssetGroupsQuery$data,
  type PortfolioAssetGroupsQuery$variables,
  default as PortfolioAssetGroupsQueryNode,
} from './__generated__/PortfolioAssetGroupsQuery.graphql';

export type {
  TransactionsViewQuery,
  TransactionStatus,
  TransactionType,
  TransactionsListInput,
  TransactionsViewQuery$data,
} from './__generated__/TransactionsViewQuery.graphql';

export {
  default as TransactionsViewQueryNode,
  type TransactionDirection,
} from './__generated__/TransactionsViewQuery.graphql';

export type { TransactionViewQuery } from './__generated__/TransactionViewQuery.graphql';

export { default as TransactionViewQueryNode } from './__generated__/TransactionViewQuery.graphql';

export type {
  ValidateCryptoAddressInput,
  ValidateCryptoAddressQuery,
} from './__generated__/ValidateCryptoAddressQuery.graphql';

export { default as ValidateCryptoAddressQueryNode } from './__generated__/ValidateCryptoAddressQuery.graphql';

export type { CountSavingsPlansViewQuery } from './__generated__/CountSavingsPlansViewQuery.graphql';

export { default as CountSavingsPlansViewQueryNode } from './__generated__/CountSavingsPlansViewQuery.graphql';

export type {
  SavingsPlansViewQuery,
  SavingsPlansInput,
} from './__generated__/SavingsPlansViewQuery.graphql';

export { default as SavingsPlansViewQueryNode } from './__generated__/SavingsPlansViewQuery.graphql';

/* --- MUTATIONS ---*/

export {
  type OnboardUserMutation,
  type OnboardUserInput,
  type OnboardUserMutation$data,
  type Appropriateness,
  type OnboardUserMutation$variables,
  type UserAML,
  default as OnboardUserMutationQueryNode,
} from './__generated__/OnboardUserMutation.graphql';

export type {
  UpdateUserMutation,
  UpdateUserInput,
  UserLanguage,
} from './__generated__/UpdateUserMutation.graphql';

export { default as UpdateUserMutationNode } from './__generated__/UpdateUserMutation.graphql';

export type {
  CreateTradeOfferMutation,
  CreateTradeOfferInput,
  TradeOfferOperation,
  AmountFor,
} from './__generated__/CreateTradeOfferMutation.graphql';

export { default as CreateTradeOfferMutationNode } from './__generated__/CreateTradeOfferMutation.graphql';

export type { AcceptTradeOfferMutation } from './__generated__/AcceptTradeOfferMutation.graphql';

export { default as AcceptTradeOfferMutationNode } from './__generated__/AcceptTradeOfferMutation.graphql';

export type {
  CreateCryptoAddressInput,
  CreateCryptoAddressMutation,
} from './__generated__/CreateCryptoAddressMutation.graphql';

export { default as CreateCryptoAddressMutationNode } from './__generated__/CreateCryptoAddressMutation.graphql';

export type { CreateTradeOfferMutationFragment$key } from './__generated__/CreateTradeOfferMutationFragment.graphql';

export type {
  UpdateBusinessUserMutation,
  UpdateBusinessUserInput,
} from './__generated__/UpdateBusinessUserMutation.graphql';

export { default as UpdateBusinessUserMutationNode } from './__generated__/UpdateBusinessUserMutation.graphql';

export type {
  CreateCryptoWithdrawalOfferMutation,
  CreateCryptoWithdrawalInput,
  CryptoAddressDestinationTagType,
} from './__generated__/CreateCryptoWithdrawalOfferMutation.graphql';

export { default as CreateCryptoWithdrawalOfferMutationNode } from './__generated__/CreateCryptoWithdrawalOfferMutation.graphql';

export type {
  AcceptWithdrawalOfferMutation,
  AcceptCryptoWithdrawalInput,
} from './__generated__/AcceptWithdrawalOfferMutation.graphql';

export { default as AcceptCryptoWithdrawalOfferMutationNode } from './__generated__/AcceptWithdrawalOfferMutation.graphql';
