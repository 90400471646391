import {
  SavingPlansFrameTranslationsKeys,
  StartSavingTranslationsKeys,
} from '../types';

type SavingPlansTranslationsKeys = SavingPlansFrameTranslationsKeys &
  StartSavingTranslationsKeys;

export const initialSavingsPlansTranslations: SavingPlansTranslationsKeys = {
  savingPlans: 'saving_plans',
  savingPlansManageButton: 'savings_plans_frame_manage_button',
  savingPlansStartSavingButton: 'savings_plan_frame_start_saving_button',
  noPlan: 'savings_plan_frame_no_plan',
  startSavingTitle: 'start_saving_sheet_title',
  startSavingSectionTitleOne: 'start_saving_sheet_section_title_1',
  startSavingSectionTitleTwo: 'start_saving_sheet_section_title_2',
  startSavingSectionTitleThree: 'start_saving_sheet_section_title_3',
  startSavingSectionDescriptionOne: 'start_saving_sheet_section_description_1',
  startSavingSectionDescriptionTwo: 'start_saving_sheet_section_description_2',
  startSavingSectionDescriptionThree:
    'start_saving_sheet_section_description_3',
  continue: 'continue',
  savingsPlanSuccessTitle: 'savings_plan_success_title',
  savingsPlanSuccessBodyLine1: 'savings_plan_success_body_line_1',
  savingsPlanSuccessBodyLine2: 'savings_plan_success_body_line_2',
  savingsPlanSuccessBodyLine3: 'savings_plan_success_body_line_3',
  savingsPlanSuccessBody2Line1: 'savings_plan_success_body2_line_1',
  savingsPlanSuccessBody2Line3: 'savings_plan_success_body2_line_3',
  savingsPlanSuccessBody2Line2: 'savings_plan_success_body2_line_2',
  okGoToPortfolio: 'ok_go_to_portfolio',
  savingsPlanSuccessCheckItOut: 'savings_plan_success_check_it_out',
  savingsPlansFailedTitle: 'savings_plan_failed_title',
  savingsPlansFailedSubtitle: 'savings_plan_failed_subtitle',
  tryAgain: 'try_again',
  cancelTransaction: 'cancel_transaction',
  poweredByBitpanda: 'powered_by_bitpanda',
};

const getSavingsPlansTranslations = (
  t: (translationKey: keyof SavingPlansTranslationsKeys) => string,
): SavingPlansTranslationsKeys => {
  return Object.entries(initialSavingsPlansTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof SavingPlansTranslationsKeys] = t(
        value as keyof SavingPlansTranslationsKeys,
      );

      return acc;
    },
    {} as SavingPlansTranslationsKeys,
  );
};

export { getSavingsPlansTranslations };
