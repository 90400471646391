'use client';

import { ReactNode } from 'react';
import { css, stack } from '@bts-web/utils-style-engine';
import { useRouter } from 'next/navigation';
import { Icon } from '@bts-web/design-system/component/icon';
import { AssetImage, ButtonBase, DialogBase } from '../../../../common';
import { AlertBox } from '../../../../common';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const titleAndDescriptionContainerStyles = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

const titleStyles = css({
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  textAlign: 'center',
  mx: 'auto',
});

export interface TransactionFailureModalProps {
  title: string;
  description: string | ReactNode;
  continueButtonText: string;
  cancelButtonText?: string;
  poweredByLabel?: string;
  logo?: {
    url: string;
    name: string;
  };
  onContinueClick: () => void;
  onCancelClick?: () => void;
  onClose: () => void;
}

export const TransactionFailureModal = ({
  title,
  description,
  continueButtonText,
  cancelButtonText,
  poweredByLabel,
  onClose,
  onContinueClick,
  onCancelClick,
  logo,
}: TransactionFailureModalProps) => {
  const router = useRouter();

  const handleClose = () => {
    router.refresh();

    onClose();
  };

  const handleContinueClick = () => {
    onClose();

    onContinueClick();
  };

  const handleCancelClick = () => {
    onClose();

    if (onCancelClick) {
      onCancelClick();
    }
  };

  return (
    <DialogBase
      id="base-modal"
      open
      onOpenChange={handleClose}
      size="medium"
      onMobilefullscreen
      visual="secondary"
      disableBackdropClose
    >
      <div className={containerStyles}>
        <div className={titleAndDescriptionContainerStyles}>
          {logo && (
            <div
              className={css({
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                p: {
                  base: 'extra_small_2',
                  lg: 'small',
                },
                backgroundColor: 'neutrals.fill_quinary',
                boxShadow: `0px 0px 18px 0px rgba(0, 0, 0, 0.25)`,
              })}
            >
              <AssetImage
                src={logo.url}
                width={48}
                height={48}
                alt={logo.name}
                priority
                testId="trade-asset-logo"
                aria-label="asset logo"
              />
            </div>
          )}
          <h1 className={titleStyles}>{title}</h1>

          <AlertBox variant="error-full">
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: 'medium',
                alignItems: 'center',
                padding: 'medium',
              })}
            >
              <Icon
                icon="dismiss-circle"
                size="24"
                theme="regular"
                data-element="icon-visual"
                data-testid="icon-error"
              />

              <p>{description}</p>
            </div>
          </AlertBox>
        </div>

        <div
          className={stack({
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '390px',
            width: '100%',
            gap: 'small',
            alignItems: 'center',
          })}
        >
          {poweredByLabel && (
            <p
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'caption.small',
                fontWeight: 'caption.small',
                lineHeight: 'caption.small',
                letterSpacing: 'caption.small',
                textAlign: 'center',
              })}
            >
              {poweredByLabel}
            </p>
          )}
          <ButtonBase onClick={handleContinueClick} visual="accent">
            {continueButtonText}
          </ButtonBase>

          <ButtonBase onClick={handleCancelClick} visual="accent">
            {cancelButtonText}
          </ButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};
