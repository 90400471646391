import Image from 'next/image';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import moneyBoxIcon from './money-box-icon.svg';
import { DatePicker } from '../../../../../common';

export const SetUpSavingsPlanBox = ({
  translations,
  startsAtValue,
  locale,
  onChangeDate,
}: {
  translations: {
    setUpSavingsPlanBoxTitle: string;
    setUpSavingsPlanBoxDescription: string;
    frequency: string;
    startsAt: string;
    monthly: string;
    ok: string;
    cancel: string;
  };
  startsAtValue: string;
  locale: string;
  onChangeDate: (date: string) => void;
}) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'large',
        boxShadow:
          '0px 4px 15px 0px rgba(0, 0, 0, 0.10), 0px 10px 20px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        px: 'medium',
        py: 'small',
      })}
    >
      <div className={css({ display: 'flex', gap: 'small' })}>
        <div
          className={css({
            padding: 'extra_small',
            border: '1px solid',
            borderColor: 'positive.stroke_primary',
            borderRadius: '4px',
            minWidth: '46px',
            height: '46px',
          })}
        >
          <Image src={moneyBoxIcon} alt="money-box" width={20} height={20} />
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_3',
          })}
        >
          <span
            className={css({
              fontSize: 'title.medium_medium',
              fontWeight: 'title.medium_medium',
              letterSpacing: 'title.medium_medium',
              lineHeight: 'title.medium_medium',
              color: 'neutrals.text_primary',
            })}
          >
            {translations.setUpSavingsPlanBoxTitle}
          </span>

          <span
            className={css({
              fontSize: 'caption.small_light',
              fontWeight: 'caption.small_light',
              letterSpacing: 'caption.small_light',
              lineHeight: 'caption.small_light',
              color: 'neutrals.text_secondary',
            })}
          >
            {translations.setUpSavingsPlanBoxDescription}
          </span>
        </div>
      </div>

      <ul>
        <li
          className={css({
            borderBottom: '1px solid',
            borderBottomColor: 'neutrals.divider',
          })}
        >
          <SavingsPlanDateConfigItem
            label={translations.frequency}
            value={translations.monthly}
          />
        </li>

        <li
          className={css({
            '& [data-element="date-picker-popover-trigger"]': {
              width: '100%',
            },
          })}
        >
          <DatePicker
            value={startsAtValue}
            onChange={onChangeDate}
            cancelLabel={translations.cancel}
            confirmLabel={translations.ok}
            sideOffset={-150}
          >
            <SavingsPlanDateConfigItem
              label={translations.startsAt}
              value={new Date(startsAtValue).toLocaleDateString(locale, {
                day: 'numeric',
                year: 'numeric',
                month: 'numeric',
              })}
              hasIndicator
            />
          </DatePicker>
        </li>
      </ul>
    </div>
  );
};

function SavingsPlanDateConfigItem({
  label,
  value,
  hasIndicator,
}: {
  label: string;
  value?: string;
  hasIndicator?: boolean;
}) {
  return (
    <div className={css({ display: 'flex', alignItems: 'center' })}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          py: 'medium',
          gap: 'extra_small_2',
          flex: 'auto',
          alignItems: 'flex-start',
          '& svg': {
            color: 'neutrals.text_secondary',
          },
        })}
      >
        <span
          className={css({
            fontSize: 'caption.medium_medium',
            fontWeight: 'caption.medium_medium',
            letterSpacing: 'caption.medium_medium',
            lineHeight: 'caption.medium_medium',
            color: 'neutrals.text_secondary',
          })}
        >
          {label}
        </span>

        <span
          className={css({
            fontSize: 'title.large',
            fontWeight: 'title.large',
            letterSpacing: 'title.large',
            lineHeight: 'title.large',
            color: 'neutrals.text_primary',
          })}
        >
          {value}
        </span>
      </div>

      {hasIndicator && <Icon icon="chevron-right" size="16" />}
    </div>
  );
}
