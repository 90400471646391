import { UserFullInfoQuery$data } from '@bts-web/data-layer/server';
import { Mutable } from '../../trade/utils';

export type InitialUserDataResponseType = NonNullable<
  Mutable<UserFullInfoQuery$data['me']>
>;

export type LowercaseUserLanguage = Lowercase<
  NonNullable<UserFullInfoQuery$data['me']>['language']
>;

export type AppUserFullInfo = Omit<
  InitialUserDataResponseType,
  'language' | '__typename'
> & {
  userType: 'User' | 'CorporateUser';
  language: LowercaseUserLanguage;
  isReadOnlyUser: boolean;
};

export enum CorporateUserRole {
  EXECUTION = 'EXECUTION',
  READONLY = 'READ_ONLY',
}
