import {
  MaximumFractionDigits,
  PrecisionFloat,
  getTargetFiatAndAssetAmount,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import { COMMON_QUICK_SELECT_ITEMS } from '../../utils/constants';
import { TradeControllerVariants } from '../../utils';
import { MaximumTradeAmountType } from '../types';
import { AmountQuickSelectItem } from './../../../common/components/AmountQuickSelectButton/AmountQuickSelectButton';

const createQuickSelectItem = (
  item: string,
  currentLocale: string,
  price: PrecisionFloat,
) => ({
  label: intlAssetValueFormatting(item, {
    locale: currentLocale,
    currency: 'EUR',
  }),
  fiatAndAssetValues: getTargetFiatAndAssetAmount({
    sourceAmount: { value: item, precision: MaximumFractionDigits.FIAT },
    targetType: 'FIAT',
    price,
  }),
  value: `${item}`,
});

export const getTradeQuickSelectButtons = ({
  transactionType,
  currentLocale,
  price,
  maximumTradeAmount,
  translatedMaxString,
}: {
  price: PrecisionFloat;
  transactionType: TradeControllerVariants;
  currentLocale: string;
  maximumTradeAmount: MaximumTradeAmountType;
  translatedMaxString: string;
}): AmountQuickSelectItem[] => {
  let quickSelectionItems = COMMON_QUICK_SELECT_ITEMS.map((item) =>
    createQuickSelectItem(item, currentLocale, price),
  );

  if (transactionType === 'sell' && maximumTradeAmount) {
    quickSelectionItems = [
      ...quickSelectionItems,
      {
        label: translatedMaxString,
        fiatAndAssetValues: {
          FIAT: maximumTradeAmount['FIAT'],
          ASSET: maximumTradeAmount['ASSET'],
        },
        value: 'MAX',
      },
    ];
  } else {
    quickSelectionItems = [
      ...quickSelectionItems,
      createQuickSelectItem('500', currentLocale, price),
    ];
  }

  return quickSelectionItems;
};
