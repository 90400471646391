'use client';

import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import {
  DatePicker as DesignSystemDatePicker,
  type DatePickerProps as DesignSystemDatePickerProps,
} from '@bts-web/design-system/component/date-picker';

type DatePickerProps = {
  sideOffset?: number;
} & DesignSystemDatePickerProps['external'];

const datePickerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '326px',
  backgroundColor: 'screen_background.primary',
  borderRadius: '13px',
  p: 'small',
  '& [data-element="date-picker-popover-trigger"]': {
    width: '100%',
  },
  '& [data-element="date-picker-caption"]': {
    fontSize: 'title.large',
    fontWeight: 'title.large',
    lineHeight: 'title.large',
    letterSpacing: 'title.large',
    color: 'neutrals.text_primary',
    height: '44px',
    px: 'extra_small_2',
    display: 'flex',
    alignItems: 'center',
  },

  '& [data-element="date-picker-nav"]': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    insetBlockStart: 0,
    insetInlineEnd: 0,
    position: 'absolute',
    boxSizing: 'border-box',
    top: 'small',
    right: 'small',
    '& button': {
      color: 'information.fill_primary',
      height: '44px',
      width: '24px',
    },
    '& > button:disabled': {
      color: 'neutrals.fill_tertiary',
    },
  },
  '& [data-element="date-picker-footer"]': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'extra_small',
    '& button': {
      color: 'information.fill_primary',
      height: '32px',
    },
    '& > button:first-child': {
      fontSize: 'label.medium_medium',
      lineHeight: 'label.medium_medium',
      letterSpacing: 'label.medium_medium',
      fontWeight: 'label.medium_medium',
    },
    '& > button:not(:disabled):last-child': {
      fontSize: 'label.large_bold',
      fontWeight: 'label.large_bold',
      lineHeight: 'label.large_bold',
      letterSpacing: 'label.large_bold',
    },
    '& > button:disabled': {
      color: 'neutrals.fill_tertiary',
    },
  },
  '& table': {
    textIndent: 0,
    borderColor: 'inherit',
    borderCollapse: 'collapse',
  },
  '& th': {
    color: 'neutrals.fill_tertiary',
    fontSize: 'body.medium',
    fontWeight: 'body.medium',
    letterSpacing: 'body.medium',
    lineHeight: 'body.medium',
    width: '40px',
  },
  '& tr': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& td': {
    color: 'neutrals.text_primary',
  },
  '& td button': {
    height: '40px',
    width: '40px',
    fontSize: 'headline.small',
    fontWeight: 'headline.small',
    lineHeight: 'headline.small',
    letterSpacing: 'headline.small',
    '&:disabled': {
      opacity: 0.4,
    },
  },
  '& td[data-today="true"] button': {
    color: 'information.fill_primary',
  },
  '& td[data-outside="true"] button': {
    visibility: 'hidden',
    opacity: '0',
  },
  '& td[data-selected="true"]': {
    borderRadius: '22px',
    backgroundColor: 'information.fill_focused',
    '& > button': {
      color: 'information.fill_primary',
      fontSize: 'headline.small_medium',
      fontWeight: 'headline.small_medium',
      lineHeight: 'headline.small_medium',
      letterSpacing: 'headline.small_medium',
    },
  },
});

const DatePicker: FC<DatePickerProps> = (props) => (
  <DesignSystemDatePicker
    overlayClassName={css({
      position: 'fixed',
      inset: '0',
      backgroundColor: 'rgba(166, 166, 166, 0.50)',
      backdropFilter: 'blur(6px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
    })}
    popperContentClassName={css({
      '&[data-side="top"]': {
        animationName: 'slideUpAndFade',
      },
      '&[data-side="bottom"]': {
        animationName: 'slideUpAndFade',
      },
    })}
    className={datePickerStyles}
    disablePast
    {...props}
  />
);

export { DatePicker };
