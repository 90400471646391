import { useState, useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { getTomorrowsISODate } from '@bts-web/design-system/component/date-picker';
import { getConvertedSummary } from '../../../utils/getConvertedSummary';
import { getInputValueFromLocalStorage } from '../../../utils/localStorageInputValueUtils';
import { TradeFormProps } from '../../types';
import { getAmountInputPlaceHolder } from '../../../../../common';
import { useTradeInput } from '../useTradeInput';
import { checkForAppropriatenessAndCreateTradeOffer } from './checkForAppropriatenessAndCreateTradeOffer';
import { createSavingsPlan } from './createSavingsPlan';

export interface IUseTradeForm {
  assetDetails: TradeFormProps['assetDetails'];
  currentTradeDetails: TradeFormProps['currentTradeDetails'];
  staticValues: TradeFormProps['staticValues'];
}

export const useTradeForm = ({
  assetDetails,
  currentTradeDetails,
  staticValues,
}: IUseTradeForm) => {
  const router = useRouter();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [savingsPlanRecurringDate, setSavingsPlanRecurringDate] =
    useState<string>(getTomorrowsISODate());

  const [initialInputValue] = useState(
    getInputValueFromLocalStorage({
      assetId: assetDetails.assetId,
    }),
  );

  const {
    onTradeInputChange,
    onQuickSelectButtonClick,
    displayType,
    onInputBlur,
    inputValue,
    errors,
    setErrors,
    onChangeDisplayType,
  } = useTradeInput({
    rootMaximumTradeAmount: currentTradeDetails.maximumTradeAmount,
    rootMinimumTradeAmount: currentTradeDetails.minimumTradeAmount,
    price: assetDetails.price,
    initialInputValue,
  });

  const onFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setIsLoading(true);

      setErrors((state) => ({ ...state, customError: null }));

      const formData = new FormData(e.currentTarget);

      if (
        staticValues.transactionType === 'buy' ||
        staticValues.transactionType === 'sell'
      ) {
        checkForAppropriatenessAndCreateTradeOffer(
          formData,
          {
            currentTradeDetails,
            assetDetails,
          },
          {
            setIsLoading,
            setErrors,
            processErrors,
            router,
          },
        );
      }

      if (staticValues.transactionType === 'savings') {
        createSavingsPlan(formData, {
          setIsLoading,
          setErrors,
          processErrors,
          router,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetDetails, currentTradeDetails],
  );

  const inputPlaceholder = useMemo(() => {
    return getAmountInputPlaceHolder({
      currency: staticValues.currency,
      displayType: displayType,
      locale: staticValues.currentLocale,
      symbol: assetDetails.symbol as string,
    });
  }, [
    assetDetails.symbol,
    displayType,
    staticValues.currency,
    staticValues.currentLocale,
  ]);

  const formHasAnyErrors = errors.tooHigh || errors.tooLow;

  const allButtonsDisabled =
    currentTradeDetails.userCannotTrade || assetDetails.assetIsNotTradeable;

  const inputContextData = useMemo(
    () => ({
      currency: staticValues.currency,
      currentLocale: staticValues.currentLocale,
      displayType,
      symbol: assetDetails.symbol as string,
      value: inputValue,
      placeholder: inputPlaceholder,
    }),
    [
      staticValues.currency,
      staticValues.currentLocale,
      displayType,
      assetDetails.symbol,
      inputValue,
      inputPlaceholder,
    ],
  );

  const convertedSummary = useMemo(
    () =>
      getConvertedSummary({
        assetPrice: assetDetails.price,
        currency: staticValues.currency,
        displayType,
        locale: staticValues.currentLocale,
        symbol: assetDetails.symbol,
        translations: {
          inclSpread: staticValues.translations.inclSpread,
        },
        value: inputValue,
        maximumTradeAmount: currentTradeDetails.maximumTradeAmount,
        showInclSpread: true,
      }),
    [
      assetDetails.price,
      assetDetails.symbol,
      displayType,
      inputValue,
      staticValues,
      currentTradeDetails.maximumTradeAmount,
    ],
  );

  return {
    formState: {
      isLoading,
      displayType,
      inputValue,
      errors,
      formHasAnyErrors,
      allButtonsDisabled,
      inputPlaceholder,
      inputContextData,
      convertedSummary,
      savingsPlanRecurringDate,
    },
    handlers: {
      onFormSubmit,
      onTradeInputChange,
      onQuickSelectButtonClick,
      onInputBlur,
      onChangeDisplayType,
      setSavingsPlanRecurringDate,
    },
  };
};
