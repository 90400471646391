'use client';

import { useRouter } from 'next/navigation';
import { TransactionFailureModal } from '../../../../common';
import { getSavingsPlansTranslations } from '../../../../savings-plans/utils/getSavingsPlansTranslations';
import { TFunction } from 'i18next';
import { ModalComponent } from '@bts-web/utils-context';
import { useClientTranslation } from '@bts-web/utils-lokalise';

export type SavingsPlansFailedModalProps = {
  assetName: string;
  assetId: string;
  assetLogoUrl: string;
};

export const SavingsPlanFailureModal: ModalComponent<
  SavingsPlansFailedModalProps
> = ({ assetName, assetLogoUrl, assetId, onClose }) => {
  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  const router = useRouter();

  const goToPortfolio = () => router.push('/assets/portfolio');

  const handleTryAgain = () => router.push(`/trade/standing-buy/${assetId}`);

  return (
    <TransactionFailureModal
      logo={{
        url: assetLogoUrl,
        name: assetName,
      }}
      title={translations.savingsPlansFailedTitle}
      description={translations.savingsPlansFailedSubtitle}
      continueButtonText={translations.tryAgain}
      cancelButtonText={translations.cancelTransaction}
      onContinueClick={handleTryAgain}
      onCancelClick={goToPortfolio}
      poweredByLabel={translations.poweredByBitpanda}
      onClose={onClose}
    />
  );
};
