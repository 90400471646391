'use client';

import { FC } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';
import { type TopBarMenuItemProps } from '../TopBarMenuItem/TopBarMenuItem';
import type { ITopDesktopHeaderBarTranslatedValues } from '../../types';
import { TopBarMenu } from '../TopBarMenu/TopBarMenu';
import { logoutAction } from '../../../../../auth/actions';
import { useClientUserInfo } from '../../../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import {
  TriggerResetTwoFaModal,
  TriggerSetupTwoFaModal,
} from '../../../../../common/';
import { useComposableModal } from '@bts-web/utils-context';
import { useDrawer } from '../../../../Drawer/DrawerContextProvider';

interface ITopBarMenuProps {
  translatedValues: Pick<
    ITopDesktopHeaderBarTranslatedValues,
    | 'frequently_asked_questions'
    | 'customer_support'
    | 'terms_and_conditions'
    | 'general_logout'
    | 'twoFa_welcome_title'
    | 'twoFa_reset_title'
  >;
  isWithinDropdown?: boolean;
}

export const UserTopBarMenu: FC<ITopBarMenuProps> = ({
  translatedValues,
  isWithinDropdown,
}) => {
  const { showModal } = useComposableModal();

  const { closeDrawer } = useDrawer();

  const {
    appName,
    localPublicFolderPathTo_FAQDocument,
    URLforTermsPage,
    URLforCustomerSupport,
  } = getAppConfig();

  const { twoFactorAuthentication } = useClientUserInfo();

  const isTwoFaSetup = twoFactorAuthentication === 'ENABLED';

  const twoFaClickAction = () => {
    closeDrawer('mobile-profile-drawer');

    isTwoFaSetup
      ? showModal(TriggerResetTwoFaModal)
      : showModal(TriggerSetupTwoFaModal);
  };

  const TopBarMenuItems: TopBarMenuItemProps[] = [
    // uncomment when PROFILE section is ready
    // {
    //   type: 'link',
    //   icon: 'person',
    //   label: translatedValues.profile,
    //   action: '/profile',
    // },
    ...(appName === 'LBBW'
      ? [
          {
            icon: 'shield',
            iconTheme: 'regular',
            label: isTwoFaSetup
              ? translatedValues.twoFa_reset_title
              : translatedValues.twoFa_welcome_title,
            type: 'button',
            action: twoFaClickAction,
          } as TopBarMenuItemProps,
          {
            icon: 'question-circle',
            iconTheme: 'regular',
            label: translatedValues.frequently_asked_questions,
            type: 'downloadLink',
            action: localPublicFolderPathTo_FAQDocument,
          } as TopBarMenuItemProps,
          {
            icon: 'call',
            label: translatedValues.customer_support,
            type: 'externalLink',
            iconTheme: 'regular',
            action: URLforCustomerSupport,
          } as TopBarMenuItemProps,
          {
            icon: 'document',
            iconTheme: 'regular',
            label: translatedValues.terms_and_conditions,
            type: 'externalLink',
            action: URLforTermsPage,
          } as TopBarMenuItemProps,
        ]
      : []),
    {
      label: translatedValues.general_logout,
      type: 'button',
      icon: 'logout',
      action: async () => {
        await logoutAction();
      },
    },
  ];

  return (
    <TopBarMenu
      isWithinDropdown={isWithinDropdown}
      TopBarMenuItems={TopBarMenuItems}
    />
  );
};
