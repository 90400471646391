import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/navigation';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { TGQLErrorResponseExtensionCode } from '@bts-web/utils-relay';
import { TradeInputErrorType } from '../useTradeInput';
import { initiateSavingsPlan } from './initiateSavingsPlan.action';
import { datadogErrorHelper } from '../../../../../common';

export const createSavingsPlan = async (
  formData: FormData,
  handlers: {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setErrors: Dispatch<SetStateAction<TradeInputErrorType>>;
    processErrors: (
      gqlResponseErrors?: TGQLErrorResponseExtensionCode[],
    ) => Promise<void>;
    router: ReturnType<typeof useRouter>;
  },
) => {
  const assetId = formData.get('assetId') as string;

  const formattedAmount = (formData.get('amount') as string).replace(/,/g, '.');

  const date = formData.get('savingsPlanRecurringDate') as string;

  try {
    await initiateSavingsPlan({ assetId, amount: formattedAmount, date });
  } catch (error) {
    datadogErrorHelper({
      errorMessage: `[ERR][CREATE SAVINGS PLAN] ${(error as Error).message}`,
      errorSeverity: DatadogErrorLevels.CRITICAL,
    });

    console.log(error);
  }

  handlers.setIsLoading(false);
};
