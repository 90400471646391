import { useEffect, useRef, useState } from 'react';
import { MutableNonNullableAsset } from '../../types';
import { getAssetById } from '../../../../common/gqlActions/getAssetById.action';
import { useClientUserInfo } from '../../../../common';

const REFRESH_TIMEOUT_IN_MS = 15000; // 15000 ms = 15 seconds

export const useTradeDataRefresher = ({
  assetId,
  initialAssetData,
}: {
  assetId: string;
  initialAssetData: MutableNonNullableAsset;
}) => {
  const [assetCurrentData, setAssetCurrentData] =
    useState<MutableNonNullableAsset>(initialAssetData);

  const progressTimer = useRef<any>(null);

  const userData = useClientUserInfo();

  const [isDataRefreshing, setIsDataRefreshing] = useState(false);

  const { role, userType, onboarded, businessApproved } = userData;

  const isUserNotApproved =
    (onboarded && !businessApproved) ||
    (role === 'READ_ONLY' && userType === 'CorporateUser');

  useEffect(() => {
    const fetchData = () => {
      setIsDataRefreshing(true);

      getAssetById(assetId)
        .then((response) => {
          setAssetCurrentData(
            response.data.assetById as MutableNonNullableAsset,
          );
        })
        .finally(() => {
          setIsDataRefreshing(false);
        });
    };

    if (!isUserNotApproved) {
      progressTimer.current = setInterval(fetchData, REFRESH_TIMEOUT_IN_MS);
    }

    return () => clearInterval(progressTimer.current);
  }, [assetId, isUserNotApproved]);

  return { isDataRefreshing, assetCurrentData };
};
