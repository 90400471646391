'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { DialogBase } from '../../../DialogBase/DialogBase';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { useCallback, useState } from 'react';
import { onCancelResetTwoFa } from '../../../../../auth';
import { useResetTwoFactorAuthState } from './utils/useResetTwoFactorAuthState';
import { ICodeResponseType, ResetTwoFaModalStep } from './utils/types';
import { ResetTwoFaInitialScreen } from './subPages/ResetTwoFaInitialScreen/ResetTwoFaInitialScreen';
import { ProgressIndicatorWithSteps } from '../../../ProgressIndicatorWithSteps/ProgressIndicatorWithSteps';
import { ResetTwoFaSelectResetMethod } from './subPages/ResetTwoFaSelectResetMethod/ResetTwoFaSelectResetMethod';
import { ResetTwoFaMethodAppCode } from './subPages/ResetTwoFaMethodAppCode/ResetTwoFaMethodAppCode';
import { ResetTwoFaConfirmationScreen } from './subPages/ResetTwoFaConfirmationScreen/ResetTwoFaConfirmationScreen';
import { ResetTwoFaMethodRecoveryCode } from './subPages/ResetTwoFaMethodRecoveryCode/ResetTwoFaMethodRecoveryCode';
import { useUserInfoContext } from '../../../../userinfo/UserInfoContext/UserInfoProvider';
import { InputCodePage } from '../shared/InputCodePage/InputCodePage';
import { RecoveryCodesPage } from '../SetupTwoFaModal/subPages/RecoveryCodesPage/RecoveryCodesPage';
import { ResetTwoFaScanQrCode } from './subPages/ResetTwoFaScanQrCode/ResetTwoFaScanQrCode';

const ResetTwoFaModal: ModalComponent = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const [iCodeResponse, setICodeResponse] = useState<ICodeResponseType>(null);

  const { refreshUserInfo } = useUserInfoContext();

  const {
    currentPage,
    onContinue,
    currentStepNumber,
    setPage,
    recoveryCodes,
    setRecoveryCodes,
  } = useResetTwoFactorAuthState();

  const { t } = useClientTranslation();

  const onDismiss = async () => {
    setLoading(true);

    await onCancelResetTwoFa();

    await refreshUserInfo();

    onClose();

    setLoading(false);
  };

  const isCloseDisabledInTheCorner =
    currentPage === ResetTwoFaModalStep.INITIAL;

  const backToPreviousStep = useCallback(() => {
    switch (currentPage) {
      case ResetTwoFaModalStep.METHOD_RECOVERY:
        return () => setPage(ResetTwoFaModalStep.METHOD_SELECTION);

      case ResetTwoFaModalStep.METHOD_AUTHENTICATOR:
        return () => setPage(ResetTwoFaModalStep.METHOD_SELECTION);

      case ResetTwoFaModalStep.METHOD_SELECTION:
        return () => setPage(ResetTwoFaModalStep.METHOD_SELECTION);

      case ResetTwoFaModalStep.INPUT_CODE_PAGE:
        return () => setPage(ResetTwoFaModalStep.SCAN_QR_PAGE);

      default:
        return undefined;
    }
  }, [currentPage, setPage]);

  return (
    <DialogBase
      id="reset-2fa-modal"
      open
      onOpenChange={onDismiss}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_reset_title')}
      animate={'top'}
      closeIcon={isCloseDisabledInTheCorner ? null : undefined}
      backAction={backToPreviousStep()}
      belowHeaderElement={
        <ProgressIndicatorWithSteps
          currentStep={currentStepNumber}
          totalSteps={7}
        />
      }
    >
      <TwoFaLayoutWrapper>
        {currentPage === ResetTwoFaModalStep.INITIAL && (
          <ResetTwoFaInitialScreen
            onContinue={onContinue}
            onDismiss={onDismiss}
            dismissLoading={loading}
          />
        )}
        {currentPage === ResetTwoFaModalStep.METHOD_SELECTION && (
          <ResetTwoFaSelectResetMethod
            dismissLoading={loading}
            onSelectAuthenticator={() =>
              setPage(ResetTwoFaModalStep.METHOD_AUTHENTICATOR)
            }
            onSelectRecovery={() =>
              setPage(ResetTwoFaModalStep.METHOD_RECOVERY)
            }
          />
        )}
        {currentPage === ResetTwoFaModalStep.METHOD_AUTHENTICATOR && (
          <ResetTwoFaMethodAppCode
            onContinue={onContinue}
            dismissLoading={loading}
            bindICodeResponse={setICodeResponse}
          />
        )}
        {currentPage === ResetTwoFaModalStep.METHOD_RECOVERY && (
          <ResetTwoFaMethodRecoveryCode
            onContinue={onContinue}
            dismissLoading={loading}
            bindICodeResponse={setICodeResponse}
          />
        )}

        {currentPage === ResetTwoFaModalStep.SCAN_QR_PAGE && (
          <ResetTwoFaScanQrCode
            onContinue={onContinue}
            secretCode={iCodeResponse?.secret}
            qrCode={iCodeResponse?.url}
          />
        )}

        {currentPage === ResetTwoFaModalStep.INPUT_CODE_PAGE && (
          <InputCodePage
            onContinue={onContinue}
            bindRecoveryCodes={(newCode: string) => setRecoveryCodes(newCode)}
            actionVariant="reset"
          />
        )}
        {currentPage === ResetTwoFaModalStep.RECOVERY_CODES_PAGE && (
          <RecoveryCodesPage
            onContinue={onContinue}
            recoveryCodes={recoveryCodes}
          />
        )}

        {currentPage === ResetTwoFaModalStep.CONFIRMATION && (
          <ResetTwoFaConfirmationScreen onClose={onClose} />
        )}
      </TwoFaLayoutWrapper>
    </DialogBase>
  );
};

export { ResetTwoFaModal };
