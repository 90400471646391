'use client';

import { FC, useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import {
  getPrecisionFloatValue,
  MaximumFractionDigits,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { useComposableModal } from '@bts-web/utils-context';
import { isBpfsAsset } from '../utils/isBpfsAsset';
import { AssetType, GLOBAL_MAX_TRADE_AMOUNT } from '../utils';
import { PoweredByText } from '../Reusable/PoweredByText/PoweredByText';
import { TradeAssetInfoDisplay } from '../../common/components/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { TradeScreenTranslations } from '../utils/getTradeTranslations';
import { AmountInputWrapper, ButtonBase } from '../../common';
import { BelowInputInfoTable } from '../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { acceptSavingsPlanAction } from './actions/acceptSavingsPlan.action';
import { AcceptTermToggle } from '../../common/components/AcceptTermToggle/AcceptTermToggle.use-client';
import { getConvertedSummary } from '../TradeParent/utils/getConvertedSummary';
import { datadogErrorHelper } from '../../common';
import { SavingsPlanSuccessModal } from './components/SavingsPlanSuccessModal/SavingsPlanSuccessModal';
import { SavingsPlanFailureModal } from './components/SavingsPlanFailureModal/SavingsPlanFailureModal';

export type AcceptSavingsPlanProps = {
  savingsPlanData: {
    id: string;
    name: string;
    asset: any;
    fiatAmount: PrecisionFloat;
    frequency: string;
    createdAt: string;
    nextRecurrence: string;
  }; // replace with typeof from mutation
  translations: TradeScreenTranslations;
  currentLocale: string;
};

export const AcceptSavingsPlan: FC<AcceptSavingsPlanProps> = ({
  translations,
  currentLocale,
  savingsPlanData,
}) => {
  const { showModal } = useComposableModal();

  const [isTermAgreed, setIsTermAgreed] = useState(false);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { asset, fiatAmount } = savingsPlanData;

  const { logoUrl, name, priceBuy, symbol: initialSymbol, __typename } = asset;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const totalSum = getPrecisionFloatValue(fiatAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const triggerAcceptSavingsPlan = async () => {
    setIsConfirmLoading(true);

    try {
      const response = await acceptSavingsPlanAction({
        savingsPlanId: savingsPlanData.id,
      });

      const acceptCryptoWithdrawal = response.data.savingsPlan;

      if (response.errors) {
        showModal(SavingsPlanFailureModal, {
          assetId: acceptCryptoWithdrawal?.asset.id,
          assetName: acceptCryptoWithdrawal?.asset.name,
          assetLogoUrl: acceptCryptoWithdrawal?.asset.logoUrl,
        });

        return;
      }

      showModal(SavingsPlanSuccessModal, {
        locale: currentLocale,
        savingsPlansData: {
          assetName: acceptCryptoWithdrawal?.asset.name,
          assetLogoUrl: acceptCryptoWithdrawal?.asset.logoUrl,
          fiatAmount: acceptCryptoWithdrawal?.fiatAmount,
          name: acceptCryptoWithdrawal.name,
          nextRecurrence: acceptCryptoWithdrawal.nextRecurrence,
        },
      });
    } catch (error) {
      showModal(SavingsPlanFailureModal, {
        assetId: asset.id,
        assetName: asset.name,
        assetLogoUrl: asset.logoUrl,
      });

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });
    }
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
        gap: 'medium',
      })}
    >
      {/* Special Input */}
      <div>
        <TradeAssetInfoDisplay
          assetData={{ logoUrl, name }}
          currentLocale={currentLocale}
          price={{ value: '2507,20', precision: 2 }}
          symbol={'EUR'}
        />

        <AmountInputWrapper
          inputContextData={{
            symbol: symbol,
            currency: 'EUR',
            currentLocale: currentLocale,
            displayType: 'FIAT',
            value: totalSum,
            placeholder: undefined,
          }}
        />

        <div
          className={css({
            pt: 'extra_small',
            pb: 'medium',
            borderBottom: '1px solid',
            borderColor: 'neutrals.divider',
          })}
        >
          <BelowInputInfoTable
            items={[
              {
                title: translations['units'],
                value: getConvertedSummary({
                  assetPrice: priceBuy,
                  currency: 'EUR',
                  displayType: 'FIAT',
                  locale: currentLocale,
                  symbol: symbol,
                  translations: { inclSpread: translations.inclSpread },
                  value: totalSum,
                  useRawSource: {
                    rawSourceDisplayType: 'FIAT',
                    rawSourceValue:
                      savingsPlanData?.fiatAmount as PrecisionFloat,
                  },
                  maximumTradeAmount: {
                    FIAT: {
                      value: GLOBAL_MAX_TRADE_AMOUNT,
                      precision: MaximumFractionDigits.FIAT,
                    },
                    ASSET: {
                      value: GLOBAL_MAX_TRADE_AMOUNT,
                      precision: MaximumFractionDigits.ASSET,
                    },
                  },
                  showInclSpread: true,
                }),
              },
            ]}
          />
        </div>
      </div>

      <BelowInputInfoTable
        items={[
          {
            title: 'Frequency',
            value: savingsPlanData.frequency,
          },
          {
            title: 'Starts at',
            value: '23/10/2024',
          },
          {
            title: 'Next payment',
            value: '23/11/2024',
          },
        ]}
      />

      <div
        className={css({
          pb: 'medium',
          marginTop: 'auto',
        })}
      >
        <AcceptTermToggle
          label="I hereby authorise Bitpanda to issue a SEPA Direct Debit mandate."
          detailedDescription="I authorise Bitpanda to collect payments from my account under a SEPA Direct Debit mandate (recurring) and instruct my bank to redeem the Direct Debits. The payment account must be in my name to ensure that the payment is properly credited to my account. Bitpanda Savings can be retracted at any time."
          termsAgreed={isTermAgreed}
          onToggleCallback={() => setIsTermAgreed(!isTermAgreed)}
        />

        <PoweredByText {...poweredByText} />

        <ButtonBase
          visual="accent"
          size="large"
          disabled={!isTermAgreed || isConfirmLoading}
          onClick={triggerAcceptSavingsPlan}
          fullWidth
        >
          {translations.confirm}

          {isConfirmLoading && (
            <Icon
              data-element="loading-icon"
              theme="filled"
              size="20"
              icon="spinner-ios"
              aria-label="loading icon"
            />
          )}
        </ButtonBase>
      </div>
    </div>
  );
};
