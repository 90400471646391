'use client';

import { useRouter } from 'next/navigation';
import { css, stack } from '@bts-web/utils-style-engine';
import { AssetImage, ButtonBase, DialogBase } from '../../../../common';
import { ModalComponent } from '@bts-web/utils-context';
import { ReactNode } from 'react';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const statusAndDescriptionContainerStyles = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

export type TransactionSuccessModalProps = {
  title: string;
  subtitle?: string;
  description?: string | ReactNode;
  continueButtonText: string;
  cancelButtonText?: string;
  poweredByLabel?: string;
  logo?: {
    url: string;
    name: string;
  };
  onContinueClick: () => void;
  onCancelClick?: () => void;
};

export const TransactionSuccessModal: ModalComponent<
  TransactionSuccessModalProps
> = ({
  logo,
  title,
  subtitle,
  description,
  continueButtonText,
  cancelButtonText,
  poweredByLabel,
  onContinueClick,
  onCancelClick,
  onClose,
}) => {
  const router = useRouter();

  const handleClose = () => {
    onClose();

    router.refresh();
  };

  const handleClick = () => {
    onClose();

    onContinueClick();
  };

  const handleCancelClick = () => {
    onClose();

    if (onCancelClick) {
      onCancelClick();
    }
  };

  return (
    <DialogBase
      id="base-modal"
      open
      onOpenChange={handleClose}
      size="medium"
      onMobilefullscreen
      visual="secondary"
      disableBackdropClose
    >
      <div className={containerStyles}>
        <div className={statusAndDescriptionContainerStyles}>
          {logo && (
            <div
              className={css({
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                p: {
                  base: 'extra_small_2',
                  lg: 'small',
                },
                backgroundColor: 'neutrals.fill_quinary',
                boxShadow: `0px 0px 18px 0px rgba(0, 0, 0, 0.25)`,
              })}
            >
              <AssetImage
                src={logo.url}
                width={48}
                height={48}
                alt={logo.name}
                priority
                testId="trade-asset-logo"
                aria-label="asset logo"
              />
            </div>
          )}

          <div className={stack({ gap: 'small' })}>
            <h1
              className={css({
                fontSize: 'headline.large_semi_bold',
                fontWeight: 'headline.large_semi_bold',
                lineHeight: 'headline.large_semi_bold',
                letterSpacing: 'headline.large_semi_bold',
                textAlign: 'center',
                mx: 'auto',
              })}
              data-testid="tradeFinishMainMessage"
            >
              {title}
            </h1>
            {subtitle && (
              <div
                className={css({
                  textAlign: 'center',
                  color: 'neutrals.text_primary',
                  fontSize: 'headline.small',
                  fontWeight: 'headline.small',
                  letterSpacing: 'headline.small',
                  lineHeight: 'headline.small',
                })}
              >
                {subtitle}
              </div>
            )}
          </div>

          <div
            className={css({
              color: 'neutrals.text_secondary',
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              letterSpacing: 'body.medium',
              lineHeight: 'body.medium',
            })}
          >
            {description}
          </div>
        </div>

        <div
          className={stack({
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '390px',
            width: '100%',
            gap: 'small',
            alignItems: 'center',
          })}
        >
          {poweredByLabel && (
            <p
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'caption.small',
                fontWeight: 'caption.small',
                lineHeight: 'caption.small',
                letterSpacing: 'caption.small',
                textAlign: 'center',
              })}
            >
              {poweredByLabel}
            </p>
          )}
          <ButtonBase onClick={handleClick} visual="accent">
            {continueButtonText}
          </ButtonBase>

          {cancelButtonText && (
            <ButtonBase onClick={handleCancelClick} visual="secondary">
              {cancelButtonText}
            </ButtonBase>
          )}
        </div>
      </div>
    </DialogBase>
  );
};
