const tradeDrawerKey = 'tradeDrawer';

const tradeDrawerShowQueryParamValue = 'visible';

const openTradeDrawer = () => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.set(tradeDrawerKey, 'visible');

  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

  window.history.pushState({}, 'Drawer', newUrl);
};

const closeTradeDrawer = () => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.delete(tradeDrawerKey);

  const entries = Object.entries(queryParams);

  const newUrl = `${window.location.pathname}${entries.length ? `?${queryParams.toString()}` : ''}`;

  window.history.pushState({}, 'Drawer', newUrl);
};

const toggleTrade = () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get(tradeDrawerKey) === 'visible') {
    closeTradeDrawer();
  } else {
    openTradeDrawer();
  }
};

export {
  openTradeDrawer,
  closeTradeDrawer,
  toggleTrade,
  tradeDrawerKey,
  tradeDrawerShowQueryParamValue,
};
