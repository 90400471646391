import { getAppConfig } from '@bts-web/core-app-config';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { FC, useState } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { css } from '@bts-web/utils-style-engine';
import { HyperLinkButtonBase } from '../../../../../HyperLinkButtonBase/HyperLinkButtonBase';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { DeleteTwoFaOnContinue, ICodeResponseType } from '../../utils/types';
import { submitResetTwoFaAppCode } from '../../../../../../../auth';

type TwoFaInputStatus = 'invalid' | 'valid' | 'incomplete';

const isInputValid = (value: string): boolean => {
  // Check if the length is at least 6 characters
  if (value.length < 6) {
    return false;
  }

  // Check if the string contains only letters (uppercase or lowercase) and numbers
  return /^[A-Za-z0-9]+$/.test(value);
};

const ResetTwoFaMethodRecoveryCode: FC<{
  onContinue: DeleteTwoFaOnContinue;
  dismissLoading?: boolean;
  bindICodeResponse: (response: ICodeResponseType) => void;
}> = ({ onContinue, dismissLoading, bindICodeResponse }) => {
  const [inputStatus, setInputStatus] =
    useState<TwoFaInputStatus>('incomplete');

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const { URLforCustomerSupport } = getAppConfig();

  const [inputValue, setInputValue] = useState('');

  const [localLoading, setLocalLoading] = useState(false);

  const { t } = useClientTranslation();

  const anyLoading = dismissLoading || localLoading;

  const onSubmitRecoveryCode = async (code: string) => {
    setLocalLoading(true);

    try {
      const resp = await submitResetTwoFaAppCode(code);

      if (resp.errors || resp.message === 'invalid code') {
        setInputStatus('invalid');

        processErrors(resp.errors);

        setLocalLoading(false);

        return;
      } else {
        setLocalLoading(false);

        onContinue();
      }

      bindICodeResponse(resp);
    } catch (error) {
      setLocalLoading(false);

      setInputStatus('invalid');

      return;
    }
  };

  const onInputValueChange = (value: string) => {
    setInputValue(value);

    setInputStatus(isInputValid(value) ? 'valid' : 'incomplete');
  };

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_reset_recovery_title')}`} />
      <TwoFaSectionDescription
        textContent={`${t('2fa_reset_recovery_description')}`}
      />
      <div>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => onInputValueChange(e.target.value)}
          placeholder={t('general_recovery_code')}
          className={`${css({
            width: '100%',
            maxWidth: '340px',
            margin: '0 auto',
            border: '1px solid',
            borderRadius: '8px',

            px: { base: 'extra_small', lg: 'small' },
            height: 'extra_large_4',
            lineHeight: 'extra_large_4',
            fontSize: 'headline.small_medium',
            ...(inputStatus === 'invalid'
              ? { borderColor: 'negative.stroke_primary' }
              : { borderColor: 'neutrals.stroke_primary' }),
          })}`}
        />
        {inputStatus === 'invalid' && (
          <p
            className={css({
              color: 'negative.text_primary',
              textAlign: 'center',
              py: 'extra_small_3',
              fontSize: 'caption.small',
            })}
          >
            {t('2fa_reset_invalid_code')}
          </p>
        )}
      </div>

      <TwoFaSectionButtonsWrapper>
        {URLforCustomerSupport && (
          <HyperLinkButtonBase
            visual={'ghost'}
            to={URLforCustomerSupport}
            className={css({
              textDecoration: 'underline',
            })}
          >
            {t('2fa_reset_option_helpdesk')}
          </HyperLinkButtonBase>
        )}
        <ButtonBase
          isLoading={anyLoading}
          onClick={() => onSubmitRecoveryCode(inputValue)}
          visual="accent"
          size="large"
          fullWidth
          disabled={anyLoading ? true : inputStatus !== 'valid'}
        >
          {t('2fa_reset_button')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaMethodRecoveryCode };
